import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageContent from '../components/UI/PageContents';
import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout innerPage={false}>
    <SEO title="Home" />
    <PageContent>
      <div>
        <p className="clearfix">
          Every special occasion deserves a celebration -{' '}
          <i>Perfect Day Events makes your event perfect.</i>
        </p>
        <p className="clearfix">
          We, Perfect Day Events have carved a niche amongst the most trusted
          names in event and entertainment industry, engaged in offering a
          comprehensive range of services to best suit our clients requirement
          within their budget. We make you day <b>perfect</b>.
        </p>
      </div>
      <div>
        <p className="clearfix">
          We strongly encourage that all events adhere to COVID-19 safety
          guidelines. We are available for in-person, contact less or virtual
          services and all bookings are done as per your budget and
          satisfaction.
        </p>
      </div>
      <div className="my-5 text-center">
        <Link className="btn btn-sm  btn-outline-danger" to="/our-services/">
          View our services
        </Link>
      </div>
    </PageContent>
  </Layout>
);

export default IndexPage;
